import React from 'react';
import logo from './assets/images/logo.svg';
import './assets/style/App.scss';
import './assets/style/header.scss';
import Nav from './components/nav/Nav';
import Routes from './Routes';

class App extends React.Component {

  constructor (props) {
    super(props)
  }

  render() {
    return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>Painel de Gestão</p>
          </header>
          <main>
            <Nav changePage={this.changePage}></Nav>
              <div id="content-container"><Routes /></div>
            </main>
        </div>
      );
    }
}

export default App;
