import React from "react"
import DashboardService from "../../services/DashboardService"
import Loading from '../commons/Loading';

class KpiItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataLoading:<Loading/>,
            indicator : {
                label: '',
                value: ''
            }
        }
    }

    componentDidMount() {
        switch( this.props.type ) {
            case 'amount':
                this.getTotalAmountIndicator();
                break;

            case 'products':
                this.getTotalProductIndicator();
                break;

            case 'clients':
                this.getTotalClientsIndicator();
                break;
        }
        
    }

    getTotalAmountIndicator() {
        DashboardService.getTotalAmountIndicator()
            .then((indicator)=>{
                this.setState({
                    indicator: indicator,
                    dataLoading:<></>
                })
            })
    }

    getTotalProductIndicator() {
        DashboardService.getTotalProductIndicator()
            .then((indicator)=>{
                this.setState({
                    indicator: indicator,
                    dataLoading:<></>
                })
            })
    }

    getTotalClientsIndicator() {
        DashboardService.getTotalClientsIndicator()
            .then((indicator)=>{
                this.setState({
                    indicator: indicator,
                    dataLoading:<></>
                })
            })
    }

    render(){
        return (
            <div className="kpi-item">
                <div className="info-block">
                    {this.state.dataLoading}
                    <p className="kpi-number">{this.state.indicator.value}</p>
                    <p className="kpi-label">{this.state.indicator.label}</p>
                </div>
            </div>
        )
    }
}

export default KpiItem