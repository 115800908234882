import axios from 'axios';

class DashboardService {

    async getTotalAmountIndicator() {
        return await new Promise(resolve=>{
            axios.get(process.env.REACT_APP_API_URL+'/reports/total-amount')
                .then((response)=>{
                    let indicator = {
                        label: 'Total vendido',
                        value: parseFloat(response.data).toFixed(2)
                    }

                    resolve(indicator)
                })
        })

    }

    async getTotalProductIndicator() {
        return await new Promise(resolve=>{
            axios.get(process.env.REACT_APP_API_URL+'/reports/total-products')
                .then((response)=>{
                    let indicator = {
                        label: 'Total de produtos',
                        value: response.data
                    }

                    resolve(indicator)
                })
        })
    }

    async getTotalClientsIndicator() {
        return await new Promise(resolve=>{
            axios.get(process.env.REACT_APP_API_URL+'/reports/total-clients')
                .then((response)=>{
                    let indicator = {
                        label: 'Total de clientes',
                        value: response.data
                    }

                    resolve(indicator)
                })
        })
    }
}

export default new DashboardService()