import React from "react";

class Notification extends React.Component {
    
    render() {
        //alert(this.props.text)
        // TODO: implementar um modal para exibir as mensagens
        return (
            <>
                <p>{this.props.text}</p>
            </>
        )
    }
}

export default Notification