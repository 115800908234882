import React from "react";
import '../../assets/style/table.scss';

class Table extends React.Component {

    constructor(props) {
        super (props)

        this.renderTableHeader = this.renderTableHeader.bind(this);
        this.renderTableData = this.renderTableData.bind(this);
    }

    renderTableHeader() {
        const headers_column = this.props.data.headers.map((item, i) => 
            <th key={'thead-'+i}>{item}</th>
        )

        const line = <tr>{headers_column}</tr>

        return <thead>{line}</thead>
    }

    renderTableData() {
        const data_columns = (column) =>{
            return Object.keys(column).map((column_item, i)=>
                <td key={'tbodytd-'+i}>{column[column_item]}</td>
            )
        }

        const data_lines = this.props.data.list.map((item, i) => 
            <tr key={'tbodytr-'+i}>
                {data_columns(item)}
            </tr>
        )

        return <tbody>{data_lines}</tbody>
    }

    render() {
        return (
            <div className="table-block">
                <table>
                    {this.renderTableHeader()}
                    {this.renderTableData()}
                </table>
            </div>
        )
    }
}

export default Table