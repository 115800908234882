import React from 'react';

class NotFound extends React.Component {
    render(){
        return (
            <>
                <h1>Página não encontrada</h1>
            </>
        )
    }
}

export default NotFound