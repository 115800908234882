import React from 'react';
import Icon from '../commons/Icon';
import { Link } from 'react-router-dom';

class NavAction extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="nav-action">
                <Link to={this.props.action}>
                    <Icon icon={this.props.icon}></Icon>
                    {this.props.title}
                </Link>
            </div>
        )
    }
}


export default NavAction