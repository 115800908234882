import axios from 'axios';

class ProductService {
    
    parseProductList(list) {
        return {
            headers:['#','Código', 'Nome', 'Valor'],
            list:list
        }
    }

    getProductModel() {
        return {
            id: null, 
            code: null, 
            name: null, 
            amount: null
        }
    }

    formatProductList(data_list) {
        let list = [];

        data_list.map( (item)=>{
            let data_item = this.getProductModel()
            data_item.id = item.id;
            data_item.code = item.code;
            data_item.name = item.name;
            data_item.amount = item.amount;

            list.push(data_item)
        });
        
        return list;
    }

    async getProductsList() {
        return await new Promise(resolve=>{
            axios.get(process.env.REACT_APP_API_URL+'/products')
                .then((response)=>{
                    let list = this.formatProductList(response.data);
                    resolve(this.parseProductList(list))
                })
        })
    }

    async saveProduct(inputs) {
        
        return await new Promise(
            (resolve)=>{
                axios.post(process.env.REACT_APP_API_URL+'/products', inputs)
                .then((response)=>{
                    resolve(response)
                })
                .catch((error)=>{
                    resolve(error.response.data)
                })
            },
            (reject)=>{
                reject()
            },
        );
    }
}

export default new ProductService()