import React from "react";
import '../../assets/style/App.scss';

class ActionButton extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        let label = this.props.label
        let default_class = ""
        let disabled = ""

        if (this.props.state === "in-action") {
            label = "Aguarde..."
            default_class = "disabled"
            disabled = "disabled"
        }

        return(
            <button 
                className={"button-action "+default_class} 
                disabled={disabled}
                onClick={this.props.action} >
                    {label} 
            </button>
        )
    }
}

export default ActionButton;