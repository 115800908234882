import axios from 'axios';

class SellService {
    
    parseDataList(list) {
        return {
            headers:['Código', 'Cliente', 'Data e hora', 'Valor', 'Produtos'],
            list:list
        }
    }

    getDataModel() {
        return {
            code: null, 
            client: null, 
            sell_date: null,
            total_amount: null,
            products: null
        }
    }

    formatDatatList(data_list) {
        let list = [];


        data_list.map( (item)=>{
            let sell_date_parts = item.sell_date.split(' ');
            let sell_date = sell_date_parts[0].split('-').reverse().join('/')+ ' '+sell_date_parts[1];

            let products = item.products.map( (item_product)=> 
                item_product.code+': '+item_product.name+'; ' );

            let data_item = this.getDataModel();
            data_item.code = item.code;
            data_item.client = item.client.code+': '+item.client.name;
            data_item.sell_date = sell_date;
            data_item.total_amount = item.total_amount;
            data_item.products = products;

            list.push(data_item);
        });
        
        return list;
    }

    async getSellsList() {
        return await new Promise(resolve=>{
            axios.get(process.env.REACT_APP_API_URL+'/sells')
                .then((response)=>{
                    let list = this.formatDatatList(response.data);
                    resolve(this.parseDataList(list))
                })
        })
    }

    async saveSell(inputs) {
        
        return await new Promise(
            (resolve)=>{
                axios.post(process.env.REACT_APP_API_URL+'/sells', inputs)
                .then((response)=>{
                    resolve(response)
                })
                .catch((error)=>{
                    resolve(error.response.data)
                })
            },
            (reject)=>{
                reject()
            },
        );
    }
}

export default new SellService()