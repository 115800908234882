import React from "react";

class PageTitle extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="page-title-block">
                <h2>{this.props.title}</h2>
            </div>
        )
    }
}

export default PageTitle