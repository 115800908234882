import React from "react";
import '../../assets/style/form.scss';
import ClientService from '../../services/ClientService';

class FormSelectClients extends React.Component {

    constructor(props) {
        super(props);
        this.state = {value: '', options:<>Carregando...</>};
    
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
        this.props.onChange(event);
    }

    componentDidMount() {
        ClientService.getClientsList()
            .then( (data) => {
                let options = data.list.map( (item, i) => {
                    return (<option key={i} value={item.id}>{item.code+': '+item.name}</option>)
                } )
                
                this.setState({
                    options: options
                })
            } )
    }

    render() {
        return(
            <div className="form-input">
                <label>{this.props.label}</label>
                <select value={this.state.value}
                    name={this.props.name}
                    onChange={this.handleChange} >
                        <option value="">Selecione um cliente</option>
                        {this.state.options}
                </select>
            </div>
        )
    }
}

export default FormSelectClients;