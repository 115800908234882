import React from "react";
import {
    Routes,
    Route,
  } from "react-router-dom";


import Dashboard from './pages/Dashboard';
import ClientsList from './pages/ClientsList';
import ClientForm from './pages/ClientForm';
import ProductsList from './pages/ProductsList';
import ProductForm from './pages/ProductForm';
import SellsList from './pages/SellsList';
import SellForm from './pages/SellForm';
import NotFound from './pages/NotFound';

class AppRoutes extends React.Component {
    render() {
        return(
            <Routes>
                <Route path="/" element={<Dashboard/>} />
                <Route path="/clients" element={<ClientsList/>} />
                <Route path="/clients/register" element={<ClientForm/>} />
                <Route path="/products" element={<ProductsList/>} />
                <Route path="/products/register" element={<ProductForm/>} />
                <Route path="/sells" element={<SellsList/>} />
                <Route path="/sells/register" element={<SellForm/>} />
            </Routes>
        )
    }
}



export default AppRoutes;