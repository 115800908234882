import React from 'react';
import PageTitle from '../components/commons/PageTitle';
import ActionButton from '../components/commons/ActionButton';
import FormInput from '../components/form/FormInput';
import ClientService from '../services/ClientService';
import Notification from '../components/commons/notification';
import '../assets/style/form.scss';

class ClientsForm extends React.Component {
    constructor(props) {
        super(props)

        this.form_config = {
            inputs: [
                {
                    label:"Código",
                    name:"code", 
                    type:"text",
                    placeHolder: "",
                    mask:"",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Nome",
                    name:"name", 
                    type:"text",
                    placeHolder: "",
                    mask:"",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"CPF",
                    name:"document", 
                    type:"text",
                    placeHolder: "",
                    mask:"999.999.999-99",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Email",
                    name:"email", 
                    type:"text",
                    placeHolder: "",
                    mask:"",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Data de nascimento",
                    name:"birthdate", 
                    type:"text",
                    placeHolder: "dd/mm/yyyy",
                    mask:"99/99/9999",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Logradouro",
                    name:"address_street", 
                    type:"text",
                    placeHolder: "",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Número",
                    name:"address_number", 
                    type:"text",
                    placeHolder: "",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Complemento",
                    name:"address_complement", 
                    type:"text",
                    placeHolder: "",
                    validation: {
                        required:false,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Bairro",
                    name:"address_neighborhood", 
                    type:"text",
                    placeHolder: "",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"CEP",
                    name:"address_zipcode", 
                    type:"text",
                    placeHolder: "",
                    mask:"99999-999",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Cidade",
                    name:"address_city", 
                    type:"text",
                    placeHolder: "",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
            ]
        }

        const initial_state = {}
        this.form_config.inputs.map((item)=>{
            initial_state[item.name] = '';
        })

        this.state = {
            form_data: initial_state,
            submitStatus:"enabled",
            notification:null
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.formSubmitHandler = this.formSubmitHandler.bind(this);
        this.showNotification = this.showNotification.bind(this);
    }
    

    renderInputs() {
        return this.form_config.inputs.map((item, i)=>(
            <FormInput
                key={"input-"+i} 
                label={item.label}
                type={item.type}
                name={item.name}
                mask={item.mask}
                validation={item.validation}
                placeHolder={item.placeHolder}
                onChange={this.inputChangeHandler}/>
        ))
    }

    inputChangeHandler(event) {
        const input_value = event.target.value;
        const input_name = event.target.name;

        const update = this.state.form_data;
        update[input_name] = input_value

        this.setState({
            form_data: update
        })
    }

    validate() {
        let valid = true;
        
        for ( let x in this.form_config.inputs ) {
            this.form_config.inputs[x].validation.status = 'normal';

            let input_empty = this.state.form_data[this.form_config.inputs[x].name].length < 1;

            if ( this.form_config.inputs[x].validation.required && input_empty ) {
                valid = false;

                this.form_config.inputs[x].validation.status = 'invalid';
            }
        }

        return valid;
    }

    formSubmitHandler(event) {
        event.preventDefault();
        this.setState({submitStatus:"in-action"})

        this.showNotification({text:"Aguarde..."})

        let validate = this.validate();
        if ( !validate ) {
            this.renderInputs()
            this.setState({submitStatus:"enabled"})
            this.showNotification({text:""})
            return false;
        }

        ClientService.saveClient(this.state.form_data)
            .then((response)=>{
                if ( !response.status ) {
                    this.showNotification({text:response.messages[0]});
                    this.setState({submitStatus:"enabled"})
                } else {
                    this.showNotification({text:"Cadastro realizado"})
                    window.location ="/clients";
                }
            })
            .catch((response)=>{
                this.showNotification({text:response.messages[0]})
                this.setState({submitStatus:"enabled"})
            })
      }


    showNotification(inputs) {
        this.setState({
            notification: <Notification text={inputs.text}/>
        })
    }

    render() {
        return (
            <>
                <PageTitle title="Cadastro de client"/>
                <div className="content-block">
                    
                    <div id="client-form-block">
                        <form>
                            <div className="form-section">
                                {this.renderInputs()}
                            </div>
                            
                            <div className="form-section">
                                <ActionButton 
                                    label="Salvar" 
                                    state={this.state.submitStatus}
                                    action={this.formSubmitHandler} />
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.notification}
            </>
        );

    }
}


export default ClientsForm;