import React from 'react';
import '../../assets/style/nav.scss';
import NavAction from './NavAction';

class Nav extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <nav id="nav-bar">
                <NavAction 
                    title="Dashboard" 
                    icon="FaHome" 
                    action="/"/>

                <NavAction 
                    title="Clientes" 
                    icon="FaUsers"
                    action="clients" />

                <NavAction 
                    title="Produtos" 
                    icon="FaClipboardList"
                    action="products" />

                <NavAction 
                    title="Vendas" 
                    icon="FaDollarSign" 
                    action="sells"/>

            </nav>
        );
    }
}

export default Nav;