import React from "react";
import InputMask from 'react-input-mask';
import '../../assets/style/form.scss';

class FormInput extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            value: this.props.value
        }
    }
    

    render() {
        return(
            <div className={'form-input '+this.props.validation.status}>
                <label>{this.props.label}</label>
                <InputMask 
                    mask={this.props.mask} 
                    name={this.props.name}
                    maskChar={null} 
                    value={this.props.value} 
                    onChange={this.props.onChange}
                    placeholder={this.props.placeHolder}>
                {/* <input 
                    type={this.props.type}
                    name={this.props.name}
                    placeholder={this.props.placeHolder}
                    onChange={this.props.onChange} /> */}
                </InputMask>
                <p className="validation-text">
                    {this.props.validation.message}
                </p>
            </div>
        )
    }
}

export default FormInput;