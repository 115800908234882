import React from 'react';
import Table from '../components/commons/Table';
import ClientService from '../services/ClientService';
import PageTitle from '../components/commons/PageTitle';
import ActionButton from '../components/commons/ActionButton';
import Loading from '../components/commons/Loading';

class ClientsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoading:<Loading/>,
            clients: {
                headers:[],
                list:[]
            } 
        }
    }

    componentDidMount() {
        ClientService.getClientsList()
            .then( (data) => {
                this.setState({
                    clients: data
                })
                this.setState({dataLoading:<></>})
            } )
    }

    newClient() {
        window.location ="/clients/register";
    }

    render () {
        return (
            <>
                <PageTitle title="Lista de clientes"/>
                <div className="content-block">
                    
                    <div id="action-block">
                        <ActionButton label="Novo Cliente" action={this.newClient} />
                    </div>

                    <div id="clients-block">
                        {this.state.dataLoading}
                        <Table data={this.state.clients}/>
                    </div>
                </div>
            </>
        );

    }
}


export default ClientsList;