import React from 'react';
import KpiItem from '../components/dashboard/KpiItem';
import PageTitle from '../components/commons/PageTitle';

class Dashboard extends React.Component {
    render () {
        return (
            <>
                <PageTitle title="Dashboard"/>
                <div className="content-block">
                    <div id="dashboard-kpis-block">
                        <KpiItem type="amount"/>
                        <KpiItem type="products"/>
                        <KpiItem type="clients"/>
                    </div>
                </div>
            </>
        );

    }
}


export default Dashboard;