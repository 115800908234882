import axios from 'axios';

class ClientService {
    
    parseClientList(list) {
        return {
            headers:['#','Código', 'Nome', 'CPF', 'Email', 'Data Nasc.', 'Endereço'],
            list:list
        }
    }

    getClientModel() {
        return {
            id: null, 
            code: null, 
            name: null, 
            document: null, 
            email: null, 
            birthdate: null, 
            address: null,
        }
    }

    formatClientList(client_list) {
        let list = [];

        client_list.map( (item)=>{
            let client_item = this.getClientModel()
            client_item.id = item.id;
            client_item.code = item.code;
            client_item.name = item.name;
            client_item.document = item.document;
            client_item.email = item.email;
            client_item.birthdate = item.birthdate.split('-').reverse().join('/');
            
            client_item.address = item.address_street+' '+item.address_number+' ';
            client_item.address += ((item.address_complement===null)?'':item.address_complement)+', ';
            client_item.address += item.address_zipcode+' '+item.address_neighborhood+' '+item.address_city;

            list.push(client_item)
        });
        
        return list;
    }

    async getClientsList() {
        return await new Promise(resolve=>{
            axios.get(process.env.REACT_APP_API_URL+'/clients')
                .then((response)=>{
                    let list = this.formatClientList(response.data);
                    resolve(this.parseClientList(list))
                })
        })
    }

    async saveClient(inputs) {
        
        return await new Promise(
            (resolve)=>{
                axios.post(process.env.REACT_APP_API_URL+'/clients', inputs)
                .then((response)=>{
                    resolve(response)
                })
                .catch((error)=>{
                    resolve(error.response.data)
                })
            },
            (reject)=>{
                reject()
            },
        );
    }
}

export default new ClientService()