import * as FaIcons from 'react-icons/fa';

function Icon(props) {
    const FaIcon = FaIcons[props.icon];
    return (
        <div className="icon">
            <FaIcon/>
        </div>
    );
}

export default Icon;