import React from 'react';
import Table from '../components/commons/Table';
import SellService from '../services/SellService';
import PageTitle from '../components/commons/PageTitle';
import ActionButton from '../components/commons/ActionButton';
import Loading from '../components/commons/Loading';

class SellsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoading:<Loading/>,
            sells: {
                headers:[],
                list:[]
            } 
        }
    }

    componentDidMount() {
        SellService.getSellsList()
            .then( (data) => {
                this.setState({
                    sells: data
                })
                this.setState({dataLoading:<></>})
            } )
    }

    newSell() {
        window.location ="/sells/register";
    }

    render () {
        return (
            <>
                <PageTitle title="Lista de vendas"/>
                <div className="content-block">
                    
                    <div id="action-block">
                        <ActionButton label="Nova Venda" action={this.newSell} />
                    </div>

                    <div id="clients-block">
                        {this.state.dataLoading}
                        <Table data={this.state.sells}/>
                    </div>
                </div>
            </>
        );

    }
}


export default SellsList;