import React from "react";

class Loading extends React.Component {
    render() {
        return(
            <div id='loading-container'></div>
        )
    }
}

export default Loading