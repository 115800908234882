import React from "react";
import '../../assets/style/form.scss';
import ProductService from '../../services/ProductService';

class FormSelectProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products_data:[],
            products_list:[],
            products_selected:<></>,
            value: '', 
            options:<>Carregando...</>
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.validateOptionSelection = this.validateOptionSelection.bind(this);
        this.removeSelectecOption = this.removeSelectecOption.bind(this);
    }

    handleChange(event) {
        const option = {
            value:event.target.value, 
            name:event.target.options[event.target.selectedIndex].text
        }

        this.validateOptionSelection(option);
    }

    validateOptionSelection(option) {
        this.setState({value:option.value});

        let add_product = true;
        let products_list = this.state.products_list;

        for ( let i in products_list ) {
            if ( products_list[i].value === option.value ) {
                add_product = false;
                products_list.splice(i, 1);
            }
        }

        if ( add_product )
            products_list.push(option);

        this.renderSelectedOptions(products_list)
    }

    renderSelectedOptions(products_list) {
        let selected_ids = [];

        let products_selected = products_list.map((item, i) => {
            selected_ids.push(parseInt(item.value));
            return <li key={'li-'+i}>
                    <div className="selected-product-item">
                        <div className="selected-product-label">{item.name}</div>
                        <div 
                            className="selected-product-action" 
                            option={JSON.stringify({value:item.value, name:item.name})}
                            onClick={this.removeSelectecOption}
                             >X</div>
                    </div>
                </li>
        })

        this.setState({products_selected:products_selected})

        let selected = this.state.products_data.filter(item => {
            if ( selected_ids.includes(item.id) ) {
                return item;
            }
        })

        this.props.onChange(selected);
    }

    removeSelectecOption(event) {
        let option = JSON.parse(event.target.getAttribute('option'));
        this.validateOptionSelection(option);
    }

    componentDidMount() {
        ProductService.getProductsList()
            .then( (data) => {
                this.setState({products_data:data.list});

                let options = data.list.map( (item, i) => {
                    return (
                        <option 
                            key={i} 
                            value={item.id} >
                                {item.code+': '+item.name+' [R$'+item.amount+']'}
                        </option>)
                } )
                
                this.setState({
                    options: options
                })
            } )
    }

    render() {
        return(
            <div className="form-input">
                <label>{this.props.label}</label>
                <select 
                    value={this.state.value} 
                    name={this.props.name}
                    onChange={this.handleChange} >
                        <option value="">Selecione um produto</option>
                        {this.state.options}
                </select>

                <div className="selected-products-block">
                    <p>Produtos selecionados:</p>
                    <ul>
                        {this.state.products_selected}
                    </ul>
                </div>
            </div>
        )
    }
}

export default FormSelectProducts;