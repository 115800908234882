import React from 'react';
import PageTitle from '../components/commons/PageTitle';
import ActionButton from '../components/commons/ActionButton';
import FormInput from '../components/form/FormInput';
import FormSelectClients from '../components/form/FormSelectClients';
import FormSelectProducts from '../components/form/FormSelectProducts';
import SellService from '../services/SellService';
import Notification from '../components/commons/notification';
import '../assets/style/form.scss';

class SellForm extends React.Component {
    constructor(props) {
        super(props)

        this.form_config = {
            inputs: [
                {
                    label:"Código",
                    name:"code", 
                    type:"text",
                    placeHolder: "",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Data e Hora Venda",
                    name:"sell_date", 
                    type:"text",
                    placeHolder: "dd/mm/yyyy hh:mm",
                    mask: "99/99/9999 99:99",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Cliente",
                    name:"client", 
                    type:"select",
                    placeHolder: "",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Produtos",
                    name:"products", 
                    type:"select",
                    placeHolder: "",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                },
                {
                    label:"Valor",
                    name:"total_amount", 
                    type:"amount",
                    placeHolder: "",
                    validation: {
                        required:true,
                        status:'normal',
                        message: 'Campo obrigatório'
                    }
                }
            ]
        }

        const initial_state = {}
        this.form_config.inputs.map((item)=>{
            initial_state[item.name] = '';
        })

        this.state = {
            form_data: initial_state,
            clients_list:[],
            products_list:[],
            total_amount:0,
            submitStatus:"enabled",
            notification:null,
            inputs: <></>
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.formSubmitHandler = this.formSubmitHandler.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.selectProductsHandler = this.selectProductsHandler.bind(this);
    }

    renderInputs() {
        return this.form_config.inputs.map((item, i)=>{
            let input_element = <></>
            if ( item.type === 'text' ) {
                    input_element = <FormInput
                        key={"input-"+i} 
                        label={item.label}
                        type={item.type}
                        name={item.name}
                        mask={item.mask}
                        validation={item.validation}
                        placeHolder={item.placeHolder}
                        onChange={this.inputChangeHandler}/>
            }

            if ( item.type === 'amount' && item.name === 'total_amount' ) {
                input_element = <FormInput
                    key={"input-"+i} 
                    label={item.label}
                    type={'text'}
                    name={item.name}
                    mask={item.mask}
                    value={this.state.total_amount}
                    validation={item.validation}
                    placeHolder={item.placeHolder}
                    onChange={this.inputChangeHandler}/>
            }
            
            if ( item.type === 'select' && item.name === 'client' ) {
                input_element = <FormSelectClients
                    key={"input-"+i} 
                    label={item.label}
                    name={item.name}
                    onChange={this.inputChangeHandler}/>
            }

            if ( item.type === 'select' && item.name === 'products' ) {
                input_element = <FormSelectProducts
                    key={"input-"+i} 
                    label={item.label}
                    name={item.name}
                    onChange={this.selectProductsHandler}/>
            }

            return (input_element)
        })
    }

    selectProductsHandler(products) {

        let update = this.state.form_data;
        update.products = products.map(item => item.id)

        let selected_total = products.reduce((prev,curr) => {
            return prev + curr.amount;
        }, 0);

        update.total_amount = selected_total;

        this.setState({
            form_data: update,
            total_amount: parseFloat(selected_total).toFixed(2)
        },()=>{
            this.setState({inputs:this.renderInputs()})
        })
        
    }

    inputChangeHandler(event) {
        const input_value = event.target.value;
        const input_name = event.target.name;

        const update = this.state.form_data;
        update[input_name] = input_value

        this.setState({
            form_data: update
        })
    }

    validate() {
        let valid = true;
        
        for ( let x in this.form_config.inputs ) {
            this.form_config.inputs[x].validation.status = 'normal';

            if ( this.state.form_data[this.form_config.inputs[x].name].length < 1 ) {
                valid = false;

                this.form_config.inputs[x].validation.status = 'invalid';
            }
        }

        return valid;
    }

    formSubmitHandler(event) {
        event.preventDefault();
        this.setState({submitStatus:"in-action"})

        this.showNotification({text:"Aguarde..."})

        let validate = this.validate();
        if ( !validate ) {
            this.renderInputs()
            this.setState({submitStatus:"enabled"})
            this.showNotification({text:""})
            return false;
        }

        SellService.saveSell(this.state.form_data)
            .then((response)=>{
                if ( !response.status ) {
                    this.showNotification({text:response.messages[0]});
                    this.setState({submitStatus:"enabled"})
                } else {
                    this.showNotification({text:"Cadastro realizado"})
                    window.location ="/sells";
                }
            })
            .catch((response)=>{
                this.showNotification({text:response.messages[0]})
                this.setState({submitStatus:"enabled"})
            })
      }


    showNotification(inputs) {
        this.setState({
            notification: <Notification text={inputs.text}/>
        })
    }

    componentDidMount() {
        this.setState({inputs:this.renderInputs()})
    }

    render() {
        return (
            <>
                <PageTitle title="Cadastro de produto"/>
                <div className="content-block">
                    
                    <div id="client-form-block">
                        <form>
                            <div className="form-section">
                                {this.renderInputs()}
                            </div>
                            
                            <div className="form-section">
                                <ActionButton 
                                    label="Salvar" 
                                    state={this.state.submitStatus}
                                    action={this.formSubmitHandler} />
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.notification}
            </>
        );

    }
}


export default SellForm;